import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Switch, Link, NavLink, useRouteMatch, useParams } from "react-router-dom";

import config from './../config';

function OrdasambondList(props) {
	const [ordasambondResults, setOrdasambondResults] = useState({ data: [] });
	const [lykilordResults, setLykilordResults] = useState({ lykilord: [] });
	let { lykilord, ofl, texti, page } = useParams();

	useEffect(() => {
		window.scrollTo(0, 0);

		fetch(config.apiRoot+'/api/ordasambond/?ord='+
				lykilord+
				(ofl ? '&ofl='+ofl : '')+
				(page ? '&offset='+((page*50)-50) : '')
			)
			.then((result) => result.json())
			.then((json) => {
				setOrdasambondResults(json);
			});

	}, [lykilord, ofl, page]);

	useEffect(() => {
		if (lykilord) {
			fetch(config.apiRoot+'/api/lykill/?offset='+lykilord)
				.then((result) => result.json())
				.then((json) => {
					console.log(json)
					setLykilordResults(json);
				});
		}

	}, [lykilord]);

	useEffect(() => {
		if (texti) {
			window.scrollTo(0, 0);

			fetch(config.apiRoot+'/api/ordasambond/?texti='+texti)
				.then((result) => result.json())
				.then((json) => {
					setOrdasambondResults(json);
				});
		}

	}, [texti]);

	const pagination = function() {
		let currentPage = parseInt(page || 1);

		let pageButtons = [];

		const maxButtonCount = 7;

		const pageSize = 50;

		const nextPageUrl = '/leit'+
			('/sida/'+(currentPage+1))+
			(lykilord ? '/'+lykilord : '')+
			(ofl ? '/'+ofl : '')
		;
		const prevPageUrl = '/leit'+
			('/sida/'+(currentPage-1))+
			(lykilord ? '/'+lykilord : '')+
			(ofl ? '/'+ofl : '')
		;

		let startPage = currentPage;

		if (startPage > Math.floor(maxButtonCount/2) && ordasambondResults.count/pageSize > maxButtonCount) {
			startPage = startPage-(Math.floor(maxButtonCount/2));
		}
		else {
			startPage = 1;
		}

		let maxPageCount = Math.ceil((ordasambondResults.count/pageSize))+1;

		maxPageCount = maxPageCount > startPage+maxButtonCount ? startPage+maxButtonCount : maxPageCount;
		maxPageCount = maxPageCount > ordasambondResults.count/pageSize ? Math.ceil(ordasambondResults.count/pageSize) : maxPageCount;

		if (startPage > 1) {
			pageButtons.push(
				<div key="1" className="page-item">
					<NavLink className="page-link btn-info" to={'/leit'+
						('/sida/1')+
						(lykilord ? '/'+lykilord : '')+
						(ofl ? '/'+ofl : '')} aria-label="Previous">
						<span aria-hidden="true">1</span>
					</NavLink>
				</div>
			);

			if (startPage > 2) {
				pageButtons.push(
					<div key="..." className="page-item">
						<div className="page-link btn-info">
							<span aria-hidden="true">...</span>
						</div>
					</div>
				);
			}
		}

		for (let i = startPage; i<maxPageCount; i++) {
			pageButtons.push(
				<div key={i} className="page-item">
					<NavLink className="page-link btn-info" to={'/leit'+
						('/sida/'+(i))+
						(lykilord ? '/'+lykilord : '')+
						(ofl ? '/'+ofl : '')} aria-label="Previous">
						<span aria-hidden="true">{i}</span>
					</NavLink>
				</div>
			);
		}

		if (Math.ceil(ordasambondResults.count/pageSize) > maxPageCount) {
			pageButtons.push(
				<div key="...." className="page-item">
					<div className="page-link btn-info">
						<span aria-hidden="true">...</span>
					</div>
				</div>
			);
		}

		pageButtons.push(
			<div key={Math.ceil((ordasambondResults.count/pageSize))} className="page-item">
				<NavLink className="page-link btn-info" to={'/leit'+
						('/sida/'+Math.ceil(ordasambondResults.count/pageSize))+
						(lykilord ? '/'+lykilord : '')+
						(ofl ? '/'+ofl : '')} aria-label="Previous">
					<span aria-hidden="true">{Math.ceil(ordasambondResults.count/pageSize)}</span>
				</NavLink>
			</div>
		);

		return <div className="mt-3 mb-3">
			<div className="pagination">
				{
					ordasambondResults.previous &&
					<div className="page-item">
						<Link className="page-link btn-info" to={prevPageUrl} aria-label="Next">
							<span aria-hidden="true"><span className="arrows">&laquo;</span> Fyrri síða</span>
							<span className="sr-only">Fyrri síða</span>
						</Link>
					</div>
				}
				{
					pageButtons
				}
				{
					ordasambondResults.next &&
					<div className="page-item">
						<Link className="page-link btn-info" to={nextPageUrl} aria-label="Next">
							<span aria-hidden="true">Næsta síða <span className="arrows">&raquo;</span></span>
							<span className="sr-only">Næsta síða</span>
						</Link>
					</div>
				}
			</div>
		</div>
	}.bind(this)

	return <div className="row">

		<div className="col-12 col-sm-5 col-md-3 hidden-print">
			<div className="card collapsible-card mobile-menu gray mb-4 tight-card">

				<div className="card-header">Lykilorð</div>

				<div className="card-body">

					<ul className="nav nav-pills flex-column">
						{
							lykilordResults.results &&
							lykilordResults.results.map((item, index) => {
								return <li className="nav-item" key={index}>
									<Link className={'nav-link pt-1 pb-1'+(item.lykill == lykilord && item.ofl == ofl ? ' active' : '')} to={'/leit/'+item.lykill+'/'+item.ofl}>{item.lykill}, {item.ofl}</Link>
								</li>
							})
						}
					</ul>

				</div>

			</div>
		</div>

		<div className="col-12 col-sm-7 col-md-9">
			{
				lykilord &&
				<div className="breadcrumb">Lykilorð:&nbsp;<strong>{lykilord}</strong></div>
			}
			{
				texti &&
				<div className="breadcrumb">Textaleit:&nbsp;<strong>{texti}</strong></div>
			}
			{
				(ordasambondResults.next || ordasambondResults.previous) &&
				pagination()
			}
			{
				ordasambondResults.results &&
				<div className="list-group dictionary-list">
					{
						ordasambondResults.results.map((item, index) => {
							return <div key={index} className="list-group-item pt-2 pb-4">
								<div className="row">
									<div className="col-9">
										<p className="text-larger">{item.texti}</p>
										{
											item.json.samheiti && item.json.samheiti.length > 0 &&
											<div className="mt-2">
												{
													item.json.samheiti.map((samheiti, index) => {
														return <div key={index}><span className="breadcrumb"><small><strong>Sh</strong> {samheiti}</small></span></div>
													})
												}
											</div>
										}
										{
											item.json.jafnheiti && item.json.jafnheiti.length > 0 &&
											<div className="mt-2">
												{
													item.json.jafnheiti.map((jafnheiti, index) => {
														return <div key={index}><span className="breadcrumb"><small><strong>Jafnheiti:</strong> {jafnheiti}</small></span></div>
													})
												}
											</div>
										}
										{
											item.json.athugasemd && item.json.athugasemd.length > 0 &&
											<div className="mt-2">
												{
													item.json.athugasemd.map((athugasemd, index) => {
														return <div key={index}><span className="breadcrumb"><small><strong>Ath:</strong> {athugasemd}</small></span></div>
													})
												}
											</div>
										}
									</div>
									{
										item.json.flettur && item.json.flettur.length > 0 &&
										<div className="col-3">
											{
												item.json.flettur.map((fletta, index) => {
													let flettaObj = fletta.split('|')
													return <div key={index}><Link to={'/leit/'+flettaObj[0]+'/'+flettaObj[1]}>{flettaObj[0]}</Link>, {flettaObj[1]}</div>
												})
											}
										</div>
									}
								</div>
							</div>
						})
					}
				</div>
			}
			{
				(ordasambondResults.next || ordasambondResults.previous) &&
				pagination()
			}
		</div>
	</div>;
}


export default OrdasambondList;
