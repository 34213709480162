import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, useRouteMatch, useParams } from "react-router-dom";

import OrdasambondList from './components/OrdasambondList';
import SearchBox from './components/SearchBox';

import logo from './img/logo-single.png';

const App = props => {
  return (
	<Router>
		<div className="header">
			<div className="container">

				<div className="logo-wrapper">
					<div className="logo">
						<img src={logo} className="mr-4" />
					</div>
					
					<div className="text">
						<div>Skrá um orðasambönd</div>
					</div>

				</div>

			</div>
		</div>

		<div className="container">
			<SearchBox />
		</div>

		<div className="container mt-5">

				<Switch>
					<Route path={'/leit/texti/:texti'}>
						<OrdasambondList />
					</Route>
					<Route path={'/leit/sida/:page/:lykilord/:ofl?'}>
						<OrdasambondList />
					</Route>
					<Route path={'/leit/:lykilord/:ofl?'}>
						<OrdasambondList />
					</Route>
					<Route path="/">
						<div>
							<hr />
							<h2>Skrá um orðasambönd</h2>
							<p>Orðasambandaskráin er unnin upp úr tölvuskráðum notkunardæmum <a href="//ritmalssafn.arnastofnun.is">Ritmálssafns Orðabókar Háskólans</a>. Dæmin spanna íslenskar ritheimildir allt frá miðri 16. öld. Í skránni birtist fjölbreytileg mynd af notkun einstakra orða í föstum samböndum og í dæmigerðu samhengi við önnur orð. Orðasambönd með algengum orðum skipta víða tugum og hundruðum og varpa sem heild skýru ljósi á merkingu og önnur notkunareinkenni orðanna. Skráin veitir einnig innsýn í sögu og samhengi íslensks orðaforða á síðari öldum.</p>

							<p>Orðasambandaskráin hefur nú að geyma rösklega 130.000 orðasambönd af ýmsu tagi, sem tengd eru u.þ.b. 50.000 lykilorðum (leitarorðum).</p>

							<p>Athugið að flest orðasamböndin ásamt mörgum öðrum má finna í <a href="//ordanet.arnastofnun.is">Íslensku orðaneti</a>.</p>
						</div>
					</Route>
				</Switch>

		</div>

	</Router>
  )
}

export default App;
