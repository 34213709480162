import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import AutocompleteInput from './AutocompleteInput';
import _ from 'underscore';

import config from "./../config";

class SearchBox extends Component {
	constructor(props) {
		super(props);

		this.inputKeyPressHandler = this.inputKeyPressHandler.bind(this);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.executeSearch = this.executeSearch.bind(this);
		this.formatAutocompleteListLink = this.formatAutocompleteListLink.bind(this);
		this.formatAutocompleteListLabel = this.formatAutocompleteListLabel.bind(this);
		this.searchBoxItemSelectHandler = this.searchBoxItemSelectHandler.bind(this);
		this.inputFocusHandler = this.inputFocusHandler.bind(this);

		this.state = {
			searchBoxInput: '',
			searchBoxTextInput: ''
		};
	}

	inputKeyPressHandler(event) {
		if (event.key == 'Enter') {
			this.executeSearch();
		}
	}

	inputChangeHandler(event) {
		var stateObj = {};

		stateObj[event.target.name] = event.target.value;

		this.setState(stateObj);
	}

	componentDidMount() {
		if (this.props.searchString) {
			this.setState({
				searchBoxInput: this.props.searchString
			});
		}

		this.refs.searchInput.refs.inputField.focus();

		setTimeout(function() {
			this.inputFocusHandler();
		}.bind(this), 100);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.searchString != prevProps.searchString) {
			this.setState({
				searchBoxInput: this.props.searchString
			});
		}
	}

	searchBoxItemSelectHandler(item) {
		this.props.history.push('/leit/'+item.lykill+'/'+item.ofl);
	}

	inputFocusHandler() {
		this.refs.searchInput.refs.inputField.select();
	}

	executeSearch() {
		if (this.state.searchBoxTextInput) {
			this.props.history.push('/leit/texti/'+this.state.searchBoxTextInput);
		}
		else {
			this.props.history.push('/leit/'+this.state.searchBoxInput);
		}
	}

	formatAutocompleteListLink(item) {
		return '/leit/'+item.lykill+'/'+item.ofl;
	}

	formatAutocompleteListLabel(item) {
		return item.lykill+', '+item.ofl;
	}

	render() {
		return (
			<React.Fragment>

				<div className="form-group row">
					<label className="ml-3">Leita í orðasambandaskrá</label>
					<div className="col-9 col-sm-10 col-md-11 d-flex"> 
						<AutocompleteInput className="flex-fill mr-2" 
							inputClassName="form-control" 
							ref="searchInput"
							responseDataField="results"
							//searchUrl={config.apiRoot+'/api/index/?limit=30&search=$s'}
							searchUrl={config.apiRoot+'/api/lykill?ord=$s*'}
							onChange={this.inputChangeHandler} 
							inputName="searchBoxInput" 
							value={this.state.searchBoxInput} 
							type="text" 
							onEnter={this.executeSearch} 
							onItemSelect={this.searchBoxItemSelectHandler}
							placeholder="Lykilorð"
							minChars={2}
							valueField="lykill"
							listLabelFormatFunc={this.formatAutocompleteListLabel}
							selectedItemClass="active"
							onFocus={this.inputFocusHandler}
							disableAutoFill={false} 
							headerText="Ýttu á enter til að leita eða veldu orðasafn úr lista til að sjá færslu."
							listLinkFormatFunc={this.formatAutocompleteListLink}
						/>

						<div className="flex-fill">
							<input className="form-control" 
								name="searchBoxTextInput" 
								value={this.state.searchBoxTextInput} 
								onChange={this.inputChangeHandler} 
								placeholder="Textaleit"
								onKeyDown={(event) => {
									if (event.keyCode == 13) {
										this.executeSearch();
									}
								}}
							/>
						</div>
						{
							/*
						<AutocompleteInput className="flex-fill" 
							inputClassName="form-control" 
							ref="searchTextInput"
							responseDataField="results"
							//searchUrl={config.apiRoot+'/api/index/?limit=30&search=$s'}
							searchUrl={config.apiRoot+'/api/lykill?ord=$s*'}
							onChange={this.inputChangeHandler} 
							inputName="searchBoxTextInput" 
							value={this.state.searchBoxTextInput} 
							type="text" 
							onEnter={this.executeSearch} 
							onItemSelect={this.searchBoxItemSelectHandler}
							placeholder="Leitarorð"
							minChars={2}
							valueField="lykill"
							listLabelFormatFunc={this.formatAutocompleteListLabel}
							selectedItemClass="active"
							onFocus={this.inputFocusHandler}
							disableAutoFill={false} 
							headerText="Ýttu á enter til að leita eða veldu orðasafn úr lista til að sjá færslu."
							listLinkFormatFunc={this.formatAutocompleteListLink}
						/>
							*/
						}
					{
						/*
						<input name="searchBoxInput"
							className="form-control"
							placeholder="Leit í orðabanka"
							type="text"
							value={this.state.searchBoxInput}
							onKeyPress={this.inputKeyPressHandler} 
							onChange={this.inputChangeHandler}
						/>
						*/
					}
					</div>
					<div className="col-2 col-md-1">
						<button onClick={this.executeSearch.bind(this)} className="btn btn-primary">Leita</button>
					</div>
				</div>

			</React.Fragment>
		);
	}
}

export default withRouter(SearchBox);
